import React from 'react'

const Map = () => {
  return (
    <div>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3690.558909514279!2d91.78711811477413!3d22.33251564736861!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30acd9af7ad9c9d9%3A0x1b4dca7bf04697ed!2sCaptains%20Dine%20Restaurant!5e0!3m2!1sbn!2sbd!4v1677734370171!5m2!1sbn!2sbd" width="90%" height="450" style={{border:"0" , borderRadius:"20px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  </div>
  )
}

export default Map