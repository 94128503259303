const indianDesert = [
  {
      "id": 1,
      "items": "Kulfi",
      "prices": 150,
      "cuisie": "Indian",
      "category": "Dessert"
  },
  {
      "id": 2,
      "items": "Shahi Kulfi",
      "prices": 190
  },
  {
      "id": 3,
      "items": "Jafrani Hot Gulab Jamun",
      "prices": 140
  },
  {
      "id": 4,
      "items": "Special Faluda",
      "prices": 200
  },
  {
      "id": 5,
      "items": "Sweet Yoghurt",
      "prices": 140
  },
  {
      "id": 6,
      "items": "Fruit Custard",
      "prices": 160
  },
  {
      "id": 7,
      "items": "Mixed Fruit Salad",
      "prices": 160
  },
  {
      "id": 8,
      "items": "Fresh Fruits with Ice Cream",
      "prices": 220
  },
  {
      "id": 9,
      "items": "Fruit Platter",
      "prices": 250
  },
  {
      "id": 10,
      "items": "Chocolate Sundae",
      "prices": 180
  },
  {
      "id": 11,
      "items": "Ice Cream/Vanilla/Strawberry/Chocolate/Mango Per Scope",
      "prices": 160
  },
  {
      "id": 12,
      "items": "Cream Caramel",
      "prices": 130
  },
  {
      "id": 13,
      "items": "Arabian Sweet",
      "prices": 110
  },
  {
      "id": 14,
      "items": "Khirsha Patishapta",
      "prices": 120
  },
  {
      "id": 15,
      "items": "Firni",
      "prices": 90
  },
  {
      "id": 16,
      "items": "Caramel Pudding",
      "prices": 140
  },
  {
      "id": 17,
      "items": "Brownie Sizzler",
      "prices": 300
  },
  {
      "id": 18,
      "items": "Brownie with Ice-Cream",
      "prices": 300
  }
];

const thaiSalads = [
  {
      "id": 1,
      "items": "Laab Kai Salad",
      "prices": 470,
      "cuisin": "Thai & Chinese",
      "category": "Salad"
  },
  {
      "id": 2,
      "items": "Mixed Salad Saucy",
      "prices": 450
  },
  {
      "id": 3,
      "items": "Grilled Chicken Salad",
      "prices": 480
  },
  {
      "id": 4,
      "items": "Grilled Beef Salad",
      "prices": 490
  },
  {
      "id": 5,
      "items": "Green Papaya Salad",
      "prices": 320
  },
  {
      "id": 6,
      "items": "Mixed Salad Dry",
      "prices": 460
  },
  {
      "id": 7,
      "items": "Russian Salad",
      "prices": 370
  },
  {
      "id": 8,
      "items": "Vegetable Salad with Cashewnut",
      "prices": 360
  },
  {
      "id": 9,
      "items": "Mixed Seafood Salad",
      "prices": 500
  }
];

const thaiAppetizers = [
  {
      "id": 1,
      "items": "Prawn on Toast",
      "prices": 395,
      "cuisin": "Thai & Chinese",
      "category": "Appetizers"
  },
  {
      "id": 2,
      "items": "Chicken on Toast",
      "prices": 395
  },
  {
      "id": 3,
      "items": "Prawn Cake",
      "prices": 470
  },
  {
      "id": 4,
      "items": "Fried Spring Roll",
      "prices": 340
  },
  {
      "id": 5,
      "items": "Fish Cake",
      "prices": 450
  },
  {
      "id": 6,
      "items": "Mixed Tempura",
      "prices": 420
  },
  {
      "id": 7,
      "items": "Captains Dine Special Mixed Appetizer",
      "prices": 520
  },
  {
      "id": 8,
      "items": "Crispy Chicken WIngs",
      "prices": 350
  },
  {
      "id": 9,
      "items": "Crispy Dry Beef",
      "prices": 499
  },
  {
      "id": 10,
      "items": "Butter Fried Prawn",
      "prices": 360
  },
  {
      "id": 11,
      "items": "Drums of Heaven",
      "prices": 360
  },
  {
      "id": 12,
      "items": "Crispy Cashewnut CHicken/Prawn",
      "prices": 420
  },
  {
      "id": 13,
      "items": "Fried Wonthon (6pcs)",
      "prices": 300
  },
  {
      "id": 14,
      "items": "Topa (Waleska)",
      "prices": 420
  },
  {
      "id": 15,
      "items": "French Fry",
      "prices": 220
  },
  {
      "id": 16,
      "items": "Fish FInger with French Fry",
      "prices": 670
  }
];

const thaiSoups = [
  {
      "id": 1,
      "items": "Captains Dine Special Soup",
      "prices": 520,
      "cuisin": "Thai & Chinese",
      "category": "Soup"
  },
  {
      "id": 2,
      "items": "Mixed Thai Soup TomYum (Thick)",
      "prices": 499
  },
  {
      "id": 3,
      "items": "Mixed Thai Soup TomYum (Clear)",
      "prices": 499
  },
  {
      "id": 4,
      "items": "Coconut Milk Soup",
      "prices": 460
  },
  {
      "id": 5,
      "items": "Chicken Vegetable Soup (Clear)",
      "prices": 350
  },
  {
      "id": 6,
      "items": "Chicken Vegetable Soup (Thick)",
      "prices": 399
  },
  {
      "id": 7,
      "items": "Thai Soup (Thick)",
      "prices": 380
  },
  {
      "id": 8,
      "items": "Chicken Corn Soup",
      "prices": 340
  },
  {
      "id": 9,
      "items": "Hot & Sour Soup",
      "prices": 460
  },
  {
      "id": 10,
      "items": "Crispy Rice Soup",
      "prices": 440
  },
  {
      "id": 11,
      "items": "Mixed Seafood Soup",
      "prices": 499
  }
];

const thaiNoodles = [
  {
      "id": 1,
      "items": "Pad Thai Noodles",
      "prices": 460,
      "cuisin": "Thai & Chinese",
      "category": "Noodles & Chopsuey"
  },
  {
      "id": 2,
      "items": "Chef Special Crispy Noodles",
      "prices": 420
  },
  {
      "id": 3,
      "items": "Captains Dine Special Chowmein",
      "prices": 480
  },
  {
      "id": 4,
      "items": "Mixed Chowmein",
      "prices": 399
  },
  {
      "id": 5,
      "items": "Chicken Chowmein",
      "prices": 360
  },
  {
      "id": 6,
      "items": "American Chopsuey",
      "prices": 399
  }
];

const thaiRices = [
  {
      "id": 1,
      "items": "Vegetable Fried Rice",
      "prices": 350,
      "cuisin": "Thai & Chinese",
      "category": "Rice"
  },
  {
      "id": 2,
      "items": "Garlic Fried Rice",
      "prices": 320
  },
  {
      "id": 3,
      "items": "Basil Leaf Fried Rice",
      "prices": 400
  },
  {
      "id": 4,
      "items": "Steam Rice",
      "prices": 110
  },
  {
      "id": 5,
      "items": "Captains Dine Special Fried Rice",
      "prices": 499
  },
  {
      "id": 6,
      "items": "Mixed Fried Rice",
      "prices": 399
  },
  {
      "id": 7,
      "items": "Chicken Fried Rice",
      "prices": 380
  },
  {
      "id": 8,
      "items": "Masala Fried Rice",
      "prices": 399
  },
  {
      "id": 9,
      "items": "Nasi Gareng Rice",
      "prices": 480
  }
];

const thaiBeefs = [
  {
      "id": 1,
      "items": "Beef with Oyster Sauce",
      "prices": 499,
      "cuisin": "Thai & Chinese",
      "category": "Beef"
  },
  {
      "id": 2,
      "items": "Beef with Basil Leaf",
      "prices": 499
  },
  {
      "id": 3,
      "items": "Beef with Garlic Sauce",
      "prices": 499
  },
  {
      "id": 4,
      "items": "Beef Chilli Onion",
      "prices": 499
  },
  {
      "id": 5,
      "items": "Beef Sizzling",
      "prices": 520
  },
  {
      "id": 6,
      "items": "Special Shredded Beef",
      "prices": 499
  }
];

const thaiPrawns = [
  {
      "id": 1,
      "items": "Prawn with Red Chilli",
      "prices": 480,
      "cuisin": "Thai & Chinese",
      "category": "Prawn"
  },
  {
      "id": 2,
      "items": "Prawn with Garlic Sauce",
      "prices": 480
  },
  {
      "id": 3,
      "items": "Prawn with Basil Leaf",
      "prices": 480
  },
  {
      "id": 4,
      "items": "Prawn with Hot Sauce",
      "prices": 480
  },
  {
      "id": 5,
      "items": "Prawn Masala",
      "prices": 480
  },
  {
      "id": 6,
      "items": "Prawn with Green Chilli Onion",
      "prices": 480
  },
  {
      "id": 7,
      "items": "Sweet & Sour Prawn",
      "prices": 480
  },
  {
      "id": 8,
      "items": "Prawn Sizzling",
      "prices": 499
  }
];

const thaiKingPrawns = [
  {
      "id": 1,
      "items": "King Prawn Masala (2pcs)",
      "prices": 550,
      "cuisin": "Thai & Chinese",
      "category": "King Prawn & lobster"
  },
  {
      "id": 2,
      "items": "Grilled King Prawn",
      "prices": 300
  },
  {
      "id": 3,
      "items": "King Prawn with Hot Sauce",
      "prices": 300
  },
  {
      "id": 4,
      "items": "Fish with Garlic Sauce",
      "prices": 550
  },
  {
      "id": 5,
      "items": "Fish Chilli Oil",
      "prices": 560
  },
  {
      "id": 6,
      "items": "Grilled Pomfret",
      "prices": 530
  },
  {
      "id": 7,
      "items": "Steam Pomfret",
      "prices": 530
  },
  {
      "id": 8,
      "items": "Grilled Red Snapper (Per 100 gram)",
      "prices": 220
  },
  {
      "id": 9,
      "items": "Steamed Red Snapper (Per 100 gram)",
      "prices": 220
  },
  {
      "id": 10,
      "items": "Fish Masala",
      "prices": 550
  },
  {
      "id": 11,
      "items": "Sweet & Sour Fish",
      "prices": 550
  },
  {
      "id": 12,
      "items": "Fried Pomfret",
      "prices": 520
  },
  {
      "id": 13,
      "items": "Grilled Lobster (Per 100 gram)",
      "prices": 480
  },
  {
      "id": 14,
      "items": "Cuttle Fish with Basil Leaf",
      "prices": 480
  },
  {
      "id": 15,
      "items": "Grilled Cuttle Fish",
      "prices": 480
  }
];

const thaiChickens = [
  {
      "id": 1,
      "items": "Grilled Chicken",
      "prices": 520,
      "cuisin": "Thai & Chinese",
      "category": "Chicken"
  },
  {
      "id": 2,
      "items": "Chicken with Basil Leaf",
      "prices": 470
  },
  {
      "id": 3,
      "items": "Chicken with Garlic Sauce",
      "prices": 480
  },
  {
      "id": 4,
      "items": "Chicken with Ginger Mushroom",
      "prices": 480
  },
  {
      "id": 5,
      "items": "Chicken with Cashewnut",
      "prices": 480
  },
  {
      "id": 6,
      "items": "Chicken Red Curry",
      "prices": 499
  },
  {
      "id": 7,
      "items": "Chicken Green Curry",
      "prices": 499
  },
  {
      "id": 8,
      "items": "Steamed Chicken",
      "prices": 530
  },
  {
      "id": 9,
      "items": "Fried Chicken",
      "prices": 470
  },
  {
      "id": 10,
      "items": "Fried Spring Chicken",
      "prices": 499
  },
  {
      "id": 11,
      "items": "Chicken in Foil Paper",
      "prices": 520
  },
  {
      "id": 12,
      "items": "Sweet & Sour Chicken",
      "prices": 499
  },
  {
      "id": 13,
      "items": "Chicken Masala",
      "prices": 499
  },
  {
      "id": 14,
      "items": "Chicken Satay",
      "prices": 530
  },
  {
      "id": 15,
      "items": "Chicken with Green Chilli Onion",
      "prices": 470
  },
  {
      "id": 16,
      "items": "Chicken Sizzling",
      "prices": 499
  }
];

const thaiCrabs = [
  {
    id: 1,
    items: "Fried Crab with Ginger",
    prices: "410",
    cuisin: "Thai & Chinese",
    category: "Crab"
  },
  {
    id: 2,
    items: "Crab in Garlic Sauce",
    prices: "399"
  },
  {
    id: 3,
    items: "Crab Hot Sauce",
    prices: "410"
  },
  {
    id: 4,
    items: "Crab Masala",
    prices: "399"
  },
  {
    id: 5,
    items: "Shredded Crab",
    prices: "510"
  }
];

const thaiVegetables = [
  {
      "id": 1,
      "items": "Mixed Vegetable with Oyster Sauce",
      "prices": 380,
      "cuisin": "Thai & Chinese",
      "category": "Vegetable"
  },
  {
      "id": 2,
      "items": "Mixed Vegetable with Chicken/Prawn",
      "prices": 370
  },
  {
      "id": 3,
      "items": "Water Cress (Kang Kong)",
      "prices": 220
  },
  {
      "id": 4,
      "items": "Boiled Vegetable with Butter",
      "prices": 340
  },
  {
      "id": 5,
      "items": "Mixed Chinese Vegetable",
      "prices": 340
  }
];

const indianAppetizers = [
  {
      "id": 1,
      "items": "Chicken Wings Garlic Touch",
      "prices": 380,
      "cuisin": "Indian",
      "category": "Appetizer"
  },
  {
      "id": 2,
      "items": "Chicken Pakura (8pcs)",
      "prices": 310
  },
  {
      "id": 3,
      "items": "Prawn Pakura (8pcs)",
      "prices": 320
  },
  {
      "id": 4,
      "items": "Vegetable Pakura",
      "prices": 240
  }
];

const indianPapadams = [
  {
      "id": 1,
      "items": "Fried Papadam (3pcs)",
      "prices": 70,
      "cuisin": "Indian",
      "category": "Papadam"
  },
  {
      "id": 2,
      "items": "Roasted Papadam (3pcs)",
      "prices": 70
  },
  {
      "id": 3,
      "items": "Masala Papadam (3pcs)",
      "prices": 100
  }
];

const indianPaneers = [
  {
      "id": 1,
      "items": "Tikka Kebab (4pcs)",
      "prices": 320,
      "cuisin": "Indian",
      "category": "Paneer"
  },
  {
      "id": 2,
      "items": "Pineapple Kebab",
      "prices": 340
  }
];

const indianSalads = [
  {
      "id": 1,
      "items": "Green Salad",
      "prices": 110,
      "cuisin": "Indian",
      "category": "Salad"
  },
  {
      "id": 2,
      "items": "Kachumber Salad",
      "prices": 160
  },
  {
      "id": 3,
      "items": "House Salad",
      "prices": 200
  },
  {
      "id": 4,
      "items": "Mixed Veg Raita Salad",
      "prices": 180
  },
  {
      "id": 5,
      "items": "Mix Vegetable Salad",
      "prices": 180
  },
  {
      "id": 6,
      "items": "Mint Raita",
      "prices": 180
  }
];

const indianBiriyanis = [
  {
      "id": 1,
      "items": "Mutton Hyderabadi",
      "prices": 299,
      "cuisin": "Indian",
      "category": "Biriyani"
  },
  {
      "id": 2,
      "items": "Beef Hyderabadi",
      "prices": 299
  },
  {
      "id": 3,
      "items": "Chicken Hyderabadi",
      "prices": 260
  },
  {
      "id": 4,
      "items": "Chef Special Go-Mu-Kha",
      "prices": 620
  },
  {
      "id": 5,
      "items": "Prawn Biriyani",
      "prices": 399
  },
  {
      "id": 6,
      "items": "Paneer Peas Biriyani",
      "prices": 240
  },
  {
      "id": 7,
      "items": "Vegetable Biriyani",
      "prices": 240
  },
  {
      "id": 8,
      "items": "Captains Dine Biriyani",
      "prices": 799
  }
];

const indianPulaos = [
  {
      "id": 1,
      "items": "Morog Pulao",
      "prices": 290,
      "cuisin": "Indian",
      "category": "Pulao"
  },
  {
      "id": 2,
      "items": "Kashmiri Pulao",
      "prices": 280
  },
  {
      "id": 3,
      "items": "Vegetable Pulao",
      "prices": 230
  },
  {
      "id": 4,
      "items": "Motor Pulao",
      "prices": 280
  },
  {
      "id": 5,
      "items": "Jeera Pulao",
      "prices": 200
  },
  {
      "id": 6,
      "items": "Plain Pulao",
      "prices": 180
  },
  {
      "id": 7,
      "items": "Steam Rice",
      "prices": 110
  }
];

const indianCurrys = [
  {
      "id": 1,
      "items": "Chicken Malai Kurma",
      "prices": 420,
      "cuisin": "Indian",
      "category": "Curry(Chicken)"
  },
  {
      "id": 2,
      "items": "Chicken Makhani",
      "prices": 420
  },
  {
      "id": 3,
      "items": "Chicken Bharta",
      "prices": 420
  },
  {
      "id": 4,
      "items": "Chicken Rimjhim",
      "prices": 420
  },
  {
      "id": 5,
      "items": "Chicken Jal-Frezi",
      "prices": 420
  },
  {
      "id": 6,
      "items": "Chicken Lajawab Makhani (Chef Special)",
      "prices": 530
  },
  {
      "id": 7,
      "items": "Chicken Butter Masala",
      "prices": 450
  },
  {
      "id": 8,
      "items": "Chicken Reshmi Butter Masala",
      "prices": 450
  },
  {
      "id": 9,
      "items": "Chicken Tikka Masala",
      "prices": 450
  },
  {
      "id": 10,
      "items": "Chicken Do Piaza",
      "prices": 420
  },
  {
      "id": 11,
      "items": "Chicken Karahi",
      "prices": 420
  }
];

const indianSeafoods = [
  {
      "id": 1,
      "items": "Tandoori Prawn",
      "prices": 380,
      "cuisin": "Indian",
      "category": "Sea food & Fish"
  },
  {
      "id": 2,
      "items": "Tandoori King Prawn (2pcs)",
      "prices": 750
  },
  {
      "id": 3,
      "items": "Fish Tikka Kebab (4pcs)",
      "prices": 480
  },
  {
      "id": 4,
      "items": "Fish Hariyali Kebab (4pcs)",
      "prices": 480
  },
  {
      "id": 5,
      "items": "Fish Irani Kebab (4pcs)",
      "prices": 530
  },
  {
      "id": 6,
      "items": "Tandoori Pomfret (Standard)",
      "prices": 550
  },
  {
      "id": 7,
      "items": "Whole Red Snapper Tandoori Standard",
      "prices": 745
  }
];

const indianCurryMuttons = [
  {
      "id": 1,
      "items": "Mutton Shahi Kurma (4pcs)",
      "prices": 499,
      "cuisin": "Indian",
      "category": "Curry(Mutton)"
  },
  {
      "id": 2,
      "items": "Mutton Rogan Josh",
      "prices": 499
  },
  {
      "id": 3,
      "items": "Mutton Vinda Aloo (Extra Hot)",
      "prices": 499
  },
  {
      "id": 4,
      "items": "Mutton Kassa Masala",
      "prices": 599
  },
  {
      "id": 5,
      "items": "Mutton Karahi",
      "prices": 499
  },
  {
      "id": 6,
      "items": "Mutton Do Piaza",
      "prices": 499
  },
  {
      "id": 7,
      "items": "Chef Special Gosh (Mutton)",
      "prices": 620
  },
  {
      "id": 8,
      "items": "Mutton Achari",
      "prices": 499
  }
];

const indianCurryBeefs = [
  {
      "id": 1,
      "items": "Traditional Beef Kala Bhuna",
      "prices": 480,
      "cuisin": "Indian",
      "category": "Curry(Beef)"
  },
  {
      "id": 2,
      "items": "Beef Punjabee",
      "prices": 450
  },
  {
      "id": 3,
      "items": "Beef Achari",
      "prices": 450
  },
  {
      "id": 4,
      "items": "Beef Keema Motor",
      "prices": 450
  },
  {
      "id": 5,
      "items": "Beef Do Piaza",
      "prices": 450
  },
  {
      "id": 6,
      "items": "Beef Bhuna",
      "prices": 450
  },
  {
      "id": 7,
      "items": "Beef Karahi Gosh",
      "prices": 450
  }
];

const indianCurryPrawns = [
  {
      "id": 1,
      "items": "Prawn Malai Curry",
      "prices": 450,
      "cuisin": "Indian",
      "category": "Curry(Prawn/Fish)"
  },
  {
      "id": 2,
      "items": "Prawn Masala",
      "prices": 480
  },
  {
      "id": 3,
      "items": "Prawn Jal Frezi",
      "prices": 450
  },
  {
      "id": 4,
      "items": "Prawn Achari",
      "prices": 450
  }
];

const indianCurryPomfrets = [
  {
      "id": 1,
      "items": "Pomfret Masala",
      "prices": 520,
      "cuisin": "Indian",
      "category": "Curry(Pomfret)"
  },
  {
      "id": 2,
      "items": "Pomfret Do Piaza",
      "prices": 520
  },
  {
      "id": 3,
      "items": "Masala Fried Pomfret",
      "prices": 520
  },
  {
      "id": 4,
      "items": "Fish Amrilsari",
      "prices": 699
  },
  {
      "id": 5,
      "items": "Fish Tikka Masala",
      "prices": 599
  },
  {
      "id": 6,
      "items": "Fish Butter Masala",
      "prices": 599
  }
];

const indianCurryDals = [
  {
      "id": 1,
      "items": "Dal Makhani",
      "prices": 270,
      "cuisin": "Indian",
      "category": "Curry(Dal)"
  },
  {
      "id": 2,
      "items": "Dal Butter Fry",
      "prices": 220
  },
  {
      "id": 3,
      "items": "Dal Bakhara",
      "prices": 290
  },
  {
      "id": 4,
      "items": "Dal Panch-Ratan",
      "prices": 320
  }
];

const indianCurryVegetables = [
  {
      "id": 1,
      "items": "Vegetable Do Piaza",
      "prices": 290,
      "cuisin": "Indian",
      "category": "Curry(Vegetable)"
  },
  {
      "id": 2,
      "items": "Vegetable Jal-Frezi",
      "prices": 290
  },
  {
      "id": 3,
      "items": "Vegetable Navratan Korma",
      "prices": 390
  },
  {
      "id": 4,
      "items": "Vegetable Malai Kofla Curry",
      "prices": 390
  },
  {
      "id": 5,
      "items": "Shahi Paneer Kofla",
      "prices": 390
  },
  {
      "id": 6,
      "items": "Paneer Butter Masala",
      "prices": 370
  },
  {
      "id": 7,
      "items": "Motor Paneer",
      "prices": 350
  },
  {
      "id": 8,
      "items": "Palak Paneer",
      "prices": 350
  },
  {
      "id": 9,
      "items": "Paneer Peshawari Karahi",
      "prices": 390
  }
];

const indianDosas = [
  {
      "id": 1,
      "items": "Plain Dosa",
      "prices": 160,
      "cuisin": "Indian",
      "category": "Dosa"
  },
  {
      "id": 2,
      "items": "Masala Dosa",
      "prices": 190
  },
  {
      "id": 3,
      "items": "Paper Masala Dosa",
      "prices": 200
  },
  {
      "id": 4,
      "items": "Paneer Masala Dosa",
      "prices": 210
  },
  {
      "id": 5,
      "items": "Coconut Masala Dosa",
      "prices": 230
  },
  {
      "id": 6,
      "items": "Hyderabadi Masala Dosa",
      "prices": 240
  },
  {
      "id": 7,
      "items": "Kashmiri Masala Dosa",
      "prices": 299
  },
  {
      "id": 8,
      "items": "Ghee Masala Dosa",
      "prices": 220
  },
  {
      "id": 9,
      "items": "Garlic Masala Dosa",
      "prices": 220
  },
  {
      "id": 10,
      "items": "Chicken Butter Masala Dosa",
      "prices": 280
  },
  {
      "id": 11,
      "items": "Special Masala Dosa",
      "prices": 350
  },
  {
      "id": 12,
      "items": "Family Masala Dosa",
      "prices": 320
  }
];

const indianFuchkas = [
  {
      "id": 1,
      "items": "Pani Fuchka",
      "prices": 130,
      "cuisin": "Indian",
      "category": "Fuchka"
  },
  {
      "id": 2,
      "items": "Dahi Fuchka",
      "prices": 160
  },
  {
      "id": 3,
      "items": "Special Fuchka",
      "prices": 210
  }
];

const indianNaans = [
  {
      "id": 1,
      "items": "Plain Naan",
      "prices": 55,
      "cuisin": "Indian",
      "category": "Naan/Paratha/Ruti/Kulcha"
  },
  {
      "id": 2,
      "items": "Butter Naan",
      "prices": 65
  },
  {
      "id": 3,
      "items": "Garlic Naan",
      "prices": 75
  },
  {
      "id": 4,
      "items": "Roghni Naan",
      "prices": 85
  },
  {
      "id": 5,
      "items": "Paneer Naan",
      "prices": 30
  },
  {
      "id": 6,
      "items": "Kabli Naan",
      "prices": 120
  },
  {
      "id": 7,
      "items": "Kashmiri Naan",
      "prices": 150
  },
  {
      "id": 8,
      "items": "Onion Kulcha",
      "prices": 80
  },
  {
      "id": 9,
      "items": "Masala Kulcha",
      "prices": 120
  },
  {
      "id": 10,
      "items": "Paneer Kulcha",
      "prices": 100
  },
  {
      "id": 11,
      "items": "Keema Kulcha",
      "prices": 150
  },
  {
      "id": 12,
      "items": "Tandoori Roti",
      "prices": 60
  },
  {
      "id": 13,
      "items": "Aloo Ka Paratha",
      "prices": 90
  },
  {
      "id": 14,
      "items": "Pudina Paratha",
      "prices": 80
  },
  {
      "id": 15,
      "items": "Lachcha Parata",
      "prices": 55
  }
];

const indianKebabs = [
  {
      "id": 1,
      "items": "Tandoori Chicken",
      "prices": 140,
      "cuisin": "Indian",
      "category": "Tandoori/Kebab"
  },
  {
      "id": 2,
      "items": "Tandoori Chicken (4 Persons)",
      "prices": 550
  },
  {
      "id": 3,
      "items": "Chicken Reshmi Kebab",
      "prices": 400
  },
  {
      "id": 4,
      "items": "Chicken Tikka Kebab",
      "prices": 400
  },
  {
      "id": 5,
      "items": "Chicken Hosh Nabi Kebab",
      "prices": 400
  },
  {
      "id": 6,
      "items": "Beef Boti Kebab",
      "prices": 430
  },
  {
      "id": 7,
      "items": "Beef Laksa Kebab",
      "prices": 430
  },
  {
      "id": 8,
      "items": "Beef Seekh Kebab",
      "prices": 430
  }
];

const captainsDineSpecials = [
    {
        "id": 1,
        "items": "Mexican Nachos",
        "prices": 480,
        "cuisin": "Indian",
        "category": "Captains Dine Special Menu"
    },
    {
        "id": 2,
        "items": "Chicken Lollipop (6 Pcs)",
        "prices": 400
    },
    {
        "id": 3,
        "items": "Fried Calamari / Squid",
        "prices": 480
    },
    {
        "id": 4,
        "items": "Wonton Noodles Soup",
        "prices": 400
    },
    {
        "id": 5,
        "items": "Mixed Seafood Fried Rice",
        "prices": 550
    },
    {
        "id": 6,
        "items": "Egg Fried Rice",
        "prices": 320
    },
    {
        "id": 7,
        "items": "Chicken / Prawn Cutlet",
        "prices": 400
    },
    {
        "id": 8,
        "items": "Grilled Mushroom",
        "prices": 350
    },
    {
        "id": 9,
        "items": "Schezwan Chicken",
        "prices": 485
    },
    {
        "id": 10,
        "items": "Seem Bharta (Beans Bharta)",
        "prices": 180
    },
    {
        "id": 11,
        "items": "Prawn Bharta",
        "prices": 300
    },
    {
        "id": 12,
        "items": "Taki fish Bharta",
        "prices": 300
    },
    {
        "id": 13,
        "items": "Balachow Bharta",
        "prices": 250
    },
    {
        "id": 14,
        "items": "Tomato Bharta",
        "prices": 180
    },
    {
        "id": 15,
        "items": "Aloo Bharta",
        "prices": 100
    },
    {
        "id": 16,
        "items": "Mutton Kacchi Biryani",
        "prices": 370
    },
    {
        "id": 17,
        "items": "Chicken Roulade (4 Persons)",
        "prices": 1200
    },
    {
        "id": 18,
        "items": "Chicken Maharaja Curry (2 Persons)",
        "prices": 475
    },
    {
        "id": 19,
        "items": "Chicken Roast (Sonali) (1 pcs)",
        "prices": 160
    }
];


const indianPizzas = [
  {
      "id": 1,
      "items": "Seafood Supreme (Pizza)",
      "prices": 888,
      "cuisin": "Indian",
      "category": "Pizza"
  },
  {
      "id": 2,
      "items": "Chicken-n-Spicy (Pizza)",
      "prices": 765
  },
  {
      "id": 3,
      "items": "Tandoori Chicken Spicy (Pizza)",
      "prices": 885
  },
  {
      "id": 4,
      "items": "Super Supreme (Pizza)",
      "prices": 875
  },
  {
      "id": 5,
      "items": "Chicken Lover’s (Pizza)",
      "prices": 805
  },
  {
      "id": 6,
      "items": "Sausage Delight (Pizza)",
      "prices": 750
  },
  {
      "id": 7,
      "items": "Hot & Spicy Chicken Lover’s (Pizza)",
      "prices": 818
  },
  {
      "id": 8,
      "items": "Tuna Supreme",
      "prices": 755
  },
  {
      "id": 9,
      "items": "Chicken-n-Mushroom Lover’s (Pizza)",
      "prices": 786
  },
  {
      "id": 10,
      "items": "Spicy Prawn Supreme (Pizza)",
      "prices": 805
  },
  {
      "id": 11,
      "items": "Beef Lover’s (Pizza)",
      "prices": 795
  },
  {
      "id": 12,
      "items": "Barbecue Chicken (Pizza)",
      "prices": 810
  },
  {
      "id": 13,
      "items": "Spicy Veggie Lover’s (Pizza)",
      "prices": 785
  },
  {
      "id": 14,
      "items": "Margerita (Pizza)",
      "prices": 765
  },
  {
      "id": 15,
      "items": "Veggie Fiesta Supreme (Pizza)",
      "prices": 785
  }
];

const indianBroastChickens = [
    {
        "id": 1,
        "items": "1pc Crispy Broast Chicken",
        "prices": 130,
        "cuisin": "Indian",
        "category": "Broast Chicken"
    },
    {
        "id": 2,
        "items": "2pcs Crispy Broast Chicken",
        "prices": 250
    },
    {
        "id": 3,
        "items": "4pcs Crispy Broast Chicken",
        "prices": 500
    },
    {
        "id": 4,
        "items": "6pcs Crispy Broast Chicken",
        "prices": 760
    },
    {
        "id": 5,
        "items": "8pcs Crispy Broast Chicken",
        "prices": 1020
    },
    {
        "id": 6,
        "items": "6pcs Pop Chicken",
        "prices": 320
    },
    {
        "id": 7,
        "items": "6pcs Chicken Nuggets",
        "prices": 300
    }
];

const indianBurgers = [
  {
      "id": 1,
      "items": "Chicken Crispy Burger",
      "prices": 310,
      "cuisin": "Indian",
      "category": "Broast Chicken"
  },
  {
      "id": 2,
      "items": "Captains Dine Burger Meal",
      "prices": 450
  },
  {
      "id": 3,
      "items": "Chicken/Beef Cheese Burger",
      "prices": 315
  },
  {
      "id": 4,
      "items": "Chicken/Beef Spicy Cheese Burger",
      "prices": 320
  },
  {
      "id": 5,
      "items": "American Chicken/Beef Double Cheese Burger",
      "prices": 462
  },
  {
      "id": 6,
      "items": "Chicken/Beef Grill Burger",
      "prices": 320
  },
  {
      "id": 7,
      "items": "Captains Dine Grill Burger Meal",
      "prices": 450
  },
  {
      "id": 8,
      "items": "Spicy Arabian Wrap",
      "prices": 320
  },
  {
      "id": 9,
      "items": "Chicken Club Sandwich",
      "prices": 300
  },
  {
      "id": 10,
      "items": "Chicken/Beef Sub Sandwich",
      "prices": 300
  }
];

const broastBeverageOnes = [
  {
      "id": 1,
      "items": "Hot Coffee",
      "prices": 150,
      "cuisin": "Indian",
      "category": "Beverage"
  },
  {
      "id": 2,
      "items": "Cold Coffee",
      "prices": 180
  },
  {
      "id": 3,
      "items": "Ice Lemon with Mint Tea",
      "prices": 110
  },
  {
      "id": 4,
      "items": "Lassi (Sweet/Salt)",
      "prices": 140
  },
  {
      "id": 5,
      "items": "Mango Lassi (Seasonal)",
      "prices": 170
  },
  {
      "id": 6,
      "items": "Mint Lassi",
      "prices": 150
  },
  {
      "id": 7,
      "items": "Jaal Jeera",
      "prices": 90
  },
  {
      "id": 8,
      "items": "Fresh Lime Juice",
      "prices": 120
  },
  {
      "id": 9,
      "items": "Fresh Lime Soda",
      "prices": 150
  },
  {
      "id": 10,
      "items": "Green Mango Juice (Seasonal)",
      "prices": 150
  },
  {
      "id": 11,
      "items": "Fresh Fruit Juice (Seasonal)",
      "prices": 160
  },
  {
      "id": 12,
      "items": "Milkshake",
      "prices": 150
  },
  {
      "id": 13,
      "items": "Oreo Milkshake",
      "prices": 180
  },
  {
      "id": 14,
      "items": "Honey Dew",
      "prices": 140
  },
  {
      "id": 15,
      "items": "The Green Lady",
      "prices": 140
  },
  {
      "id": 16,
      "items": "Mint Lemon Juice",
      "prices": 150
  },
  {
      "id": 17,
      "items": "Ice Lemon Tea",
      "prices": 140
  },
  {
      "id": 18,
      "items": "Virgin Pina Coloda",
      "prices": 170
  }
];

const broastBeverageTwos = [
  {
      "id": 1,
      "items": "Blue Moon",
      "prices": 160,
      "cuisin": "Indian",
      "category": "Beverage"
  },
  {
      "id": 2,
      "items": "Blue Moktel",
      "prices": 10
  },
  {
      "id": 3,
      "items": "Glass of Soft Drinks",
      "prices": 35
  },
  {
      "id": 4,
      "items": "Mineral Water (500 ml)",
      "prices": 20
  },
  {
      "id": 5,
      "items": "Mineral Water (1500 ml)",
      "prices": 35
  },
  {
      "id": 6,
      "items": "Tea",
      "prices": 60
  },
  {
      "id": 7,
      "items": "Borhani",
      "prices": 80
  }
];

const setMenus = [
  {
      "id": 1,
      "items": "Morog Pulao\nBoiled Egg & Salad\n",
      "prices": 280,
      "cuisin": "Indian",
      "category": "Set Menu / Quick Box"
  },
  {
      "id": 2,
      "items": "Hyderabadi Biriyani\nChicken Tikka Masala\nMixed Salad\nSoft Drinks\n",
      "prices": 380
  },
  {
      "id": 3,
      "items": "Naan/Paratha\nTandoori Chicken\nDal Makhani\nSoft Drinks\n",
      "prices": 350
  },
  {
      "id": 4,
      "items": "Soup (1 Cup)\nWonthon (2pcs)\nSoft Drinks\n",
      "prices": 270
  },
  {
      "id": 5,
      "items": "Soup (1 Cup)\nTopa (2pcs)\nSoft Drinks\n",
      "prices": 320
  },
  {
      "id": 6,
      "items": "Plain Pulao\nChicken Roast/Tandoori Chicken\nMixed Vegetable",
      "prices": 350
  }
];

const quickBoxs = [
  {
      "id": 1,
      "items": "Plain Rice\nPomfret Fry\nBeef Bhuna\nDal Butter Fry\nMixed Vegetable\n",
      "prices": 550,
      "cuisin": "Indian",
      "category": "Set Menu / Quick Box"
  },
  {
      "id": 2,
      "items": "Naan/Paratha\nTandoori Chicken\nSoft Drinks\n\n",
      "prices": 280
  },
  {
      "id": 3,
      "items": "Egg Fried Rice\nFried Pomfret\nMasala Chicken\nMixed Vegetable\n",
      "prices": 499
  },
  {
      "id": 4,
      "items": "Egg Fried Rice\nChicken Fry\nMixed Vegetable",
      "prices": 420
  }
];

const specialMenus = [
  {
    "id": 1,
    "items":
      "Captains Dine Fried Rice\nSchewan Chicken\nFried Prawn/Fried Chicken (2pcs)\nSweets\nDrinks\n",
    "prices": "550",
    "cuisin": "Indian",
    "category": "Special Menu"
  }
];

export default indianDesert;

export {
    thaiSalads,
    thaiAppetizers,
    thaiSoups,
    thaiNoodles,
    thaiRices,
    thaiBeefs,
    thaiPrawns,
    thaiKingPrawns,
    thaiChickens,
    thaiCrabs,
    thaiVegetables,
    indianAppetizers,
    indianPapadams,
    indianPaneers,
    indianSalads,
    indianBiriyanis,
    indianPulaos,
    indianCurrys,
    indianSeafoods,
    indianCurryMuttons,
    indianCurryBeefs,
    indianCurryPrawns,
    indianCurryPomfrets,
    indianCurryDals,
    indianCurryVegetables,
    indianDosas,
    indianFuchkas,
    indianNaans,
    indianKebabs,
    captainsDineSpecials,
    indianPizzas,
    indianBroastChickens,
    indianBurgers,
    broastBeverageOnes,
    broastBeverageTwos,
    setMenus,
    quickBoxs,
    specialMenus
};

